import { CartService } from './../../../core/http/cart.service';
import { take } from 'rxjs/operators';
import { ProductQuery } from './../../../shared/models/product-query';
import { ProductsService } from 'src/app/core/http/products.service';
import { Injectable } from '@angular/core';
import { CategoriesService } from 'src/app/core/http/categories.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(
    private categoriesService: CategoriesService,
    private cartService: CartService
  ) { }

  getCategories(){
    return this.categoriesService.getCategories().pipe(take(1));
  }

  getCart(customerId: string){
    return this.cartService.getCart(customerId).pipe(take(1));
  }
}
