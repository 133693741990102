import { CartResponse } from './../models/cart-response';
import { CartRequest } from './../models/cart-request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubmitCartRequest } from '../models/submit-cart-request';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private httpClient: HttpClient) {}

  updateCart(cartRequest: CartRequest): Observable<CartResponse> {
    return this.httpClient.post<CartResponse>(
      `${environment.serverUrl}/api/ecommerce/${environment.organization.externalId}/cart/update`,
      cartRequest
    );
  }

  getCart(customerId: string): Observable<CartResponse> {
    return this.httpClient.get<CartResponse>(
      `${environment.serverUrl}/api/ecommerce/${environment.organization.externalId}/cart/get?customer_id=${customerId}`
    );
  }

  submitCart(customerId: string, request: SubmitCartRequest): Observable<any> {
    return this.httpClient.post(
      `${environment.serverUrl}/api/ecommerce/${environment.organization.externalId}/cart/submit?customer_id=${customerId}`,
      request
    );
  }
}
