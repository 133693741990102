import { Component, OnInit } from '@angular/core';
import { PageOverlayService } from './page-overlay.service';

@Component({
  selector: 'app-page-overlay',
  templateUrl: './page-overlay.component.html',
  styleUrls: ['./page-overlay.component.scss']
})
export class PageOverlayComponent implements OnInit {

  isVisible: boolean = true;
  
  constructor(private pageOverlayService: PageOverlayService) { }

  ngOnInit(): void {
    this.pageOverlayService.visibility.subscribe(isVisible => this.isVisible = isVisible);
  }

  onClick(): void{
    this.pageOverlayService.click.next(null);
  }

}
