import { ImageUrlService } from './../../../core/services/image-url.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { debounceTime, tap } from 'rxjs/operators';
import { ProductsService } from 'src/app/core/http/products.service';
import { Product } from 'src/app/core/models/product';
import { PageOverlayService } from '../../page-overlay/page-overlay.service';
import { SortDirection } from './../../../shared/enums/sort-directon';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  faSpinner = faSpinner;
  showResults = false;
  totalResults: number;
  searchResults: Product[] = [];
  isLoading = false;
  searchField = new FormControl();
  selectedIndex: number = -1;
  customerId: number;
  defaultDiscount: number;


  constructor(
    public imageUrlService: ImageUrlService,
    private pageOverlayService: PageOverlayService,
    private productsService: ProductsService,
    private routerService: Router,
    private authenticationService: AuthenticationService
    ) { }

  ngOnInit(): void {
    this.initSearch();
    this.authenticationService.customer.subscribe(customer => {
      this.customerId = customer ? customer.id : null;
      this.defaultDiscount = customer ? customer.default_discount : 0;
    });
  }


  initSearch() {
    this.searchField.valueChanges.pipe(
      tap(() => this.isLoading = true),
      debounceTime(350)
    ).subscribe(() => this.search());
    
    this.pageOverlayService.click.subscribe(event => {
      this.searchResults = [];
      this.showResults = false;
      this.pageOverlayService.hide();
    });
  }

  search(){
    this.selectedIndex = -1;
    if (!this.searchField.value || this.searchField.value.trim().length === 0){
      this.isLoading = false;
      this.searchResults = [];
      this.showResults = false;
      this.closeAutoComplete('close');
      return;
    }
    const queryParams = {
      search: this.searchField.value,
      sortBy: 'price',
      current_page: 0,
      length: 5,
      sort_direction: SortDirection.DESC,
      customer_id: this.customerId ? this.customerId.toString() : null,
      category_id: ''
    };
    this.productsService.getProducts(queryParams).subscribe(res => {
      this.searchResults = res.records;
      this.totalResults = res.total;
      this.isLoading = false;
      this.openAutoComplete();

      if(this.totalResults === 0){
        this.closeAutoComplete('close');
      }
    });
  }

  closeAutoComplete(event){
    if (!event.relatedTarget?.classList?.contains('search__suggestion') || event === 'close'){
      this.showResults = false;
      this.pageOverlayService.hide();
    }
  }

  openAutoComplete(){
    if(this.searchResults.length){
      this.pageOverlayService.show();
      this.showResults = true;
      this.selectedIndex = -1;
    }
  }

  goToProductPage(index){
    if(index >= 0 && index < this.searchResults.length){
      this.routerService.navigate(['/product', this.searchResults[index].product.slug]);
    }else if(index === this.searchResults.length || index === -1){
      this.routerService.navigate(['/category'], { queryParams: { search: this.searchField.value } });
    }

    this.closeAutoComplete('close');
  }

}
