import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageOverlayComponent } from './page-overlay.component';
import { PageOverlayService } from './page-overlay.service';


@NgModule({
  declarations: [PageOverlayComponent],
  imports: [
    CommonModule
  ],
  providers: [PageOverlayService],
  exports: [
    PageOverlayComponent
  ]
})
export class PageOverlayModule { }
