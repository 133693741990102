import { DiscountService } from '../services/discount.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/core/models/product';
import { CartItem } from 'src/app/core/models/cart-item';

@Pipe({
  name: 'discountedPrice'
})
export class DiscountedPricePipe implements PipeTransform {
  constructor(private discountService: DiscountService) {}

  transform(value: Product|CartItem, defaultDiscount: number): number {
    const product = value;
    let price = product.product.price;
    const discounts = [defaultDiscount, 0 , 0];
    if (product.pricing){
      price = product.pricing.price;
      discounts[0] = product.pricing.discount_1 !== 0 ? product.pricing.discount_1 : discounts[0];
      discounts[1] = product.pricing.discount_2;
      discounts[2] = product.pricing.discount_2;
    }
    if (product.deal){
      discounts[0] =  Math.round(((price - product.deal.price) / price) * 100 * 10000) / 10000;
      discounts[1] = 0;
      discounts[2] = 0;
    }

    return this.discountService.calculateDiscountedPrice(price, discounts);
  }

}
