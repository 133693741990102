import { ChangeDetectorRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { SafeHtmlPipe } from './pipes/safe-html';
import { FormsModule } from '@angular/forms';
import { DiscountedPricePipe } from './pipes/discounted-price.pipe';
import { OriginalPricePipe } from './pipes/original-price.pipe';
import { DiscountsPipe } from './pipes/discounts.pipe';

@NgModule({
    declarations: [SafeHtmlPipe, DiscountedPricePipe, OriginalPricePipe, DiscountsPipe],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FontAwesomeModule,
        FormsModule
    ],
    exports: [
        TranslateModule,
        FontAwesomeModule,
        RouterModule,
        SafeHtmlPipe,
        FormsModule,
        DiscountedPricePipe,
        OriginalPricePipe,
        DiscountsPipe
    ],
    providers : []
})
export class SharedModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
  }