import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, throwError } from 'rxjs';
import { environment } from '../../theme/environment';
import { LoginApiService } from '../http/login.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(private loginService: LoginApiService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next));
  }

  private handleRequestWithToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: string
  ) {
    return next.handle(
      request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
  }

  private async handle(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<unknown>> {
    if (
      !request.url.startsWith(`${environment.serverUrl}/api`) ||
      request.url === `${environment.serverUrl}/api/token` ||
      request.url ===
        `${environment.serverUrl}/api/ecommerce/${environment.organization.externalId}/token`
    ) {
      return await next.handle(request).toPromise();
    }

    const token = await this.loginService.getApiToken();

    try {
      return await this.handleRequestWithToken(request, next, token)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          })
        )
        .toPromise();
    } catch (error) {
      if (error.status === 401) {
        const response = await this.loginService.retrievePublicToken();

        return await this.handleRequestWithToken(
          request,
          next,
          response.access_token
        ).toPromise();
      }
    }
  }
}
