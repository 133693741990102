import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageUrlService {

  constructor() { }

  getImageUrl(id: number): string{
    return `${environment.serverUrl}/images/products/${id}`;
  }

  getCategoryIcon(id: number): string{
    return `${environment.serverUrl}/images/categories/${id}/icon`;
  }

  getBrandImageUrl(id: number): string{
    return `${environment.serverUrl}/images/brands/${id}`;
  }
}
