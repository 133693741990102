<footer class="footer">
    <div class="footer__top container">
        <div class="row">
            <div class="col-12 col-lg-6 col-footer">
                <div class="footer__column-wrapper">
                    <div class="footer__logo">
                        <img src="{{ environment.organization.logoUrl }}" alt="{{ environment.organization.companyName }}" class="logo">
                    </div>
                    <p class="mt-2">{{ environment.footer.introductionText }}</p>
                </div>
            </div>
            <!-- <div class="col-6 col-lg-3">
                <div class="footer__column-wrapper">
                    <h3>Iscriviti alla newsletter</h3>
                    <div class="footer__newsletter">
                        <p>
                            Sign up for our mailing list to get latest updates and offers .
                        </p>
                            <div class="input-group newsletter__form">
                                <input class="form-control" type="email" placeholder="Indirizzo email">
                                <span class="input-group-append">
                                    <button class="btn" type="button">
                                        <i class="icon icon-arrow"></i>
                                    </button>
                                  </span>
                            </div>
                    </div>
                </div>
            </div> -->
            <div class="col-6 col-lg-2">
                <div class="footer__column-wrapper">
                    <h3>Informazioni</h3>
                    <ul class="footer__menu">
                        <li class="menu__item" *ngFor="let item of environment.menu.footer">
                            <a
                            *ngIf="item.routerLink; else hrefLink"
                            [routerLink]="item.routerLink"
                            >{{ item.label }}</a
                          >
                          <ng-template #hrefLink>
                            <a href="{{ item.href }}">{{ item.label }}</a>
                          </ng-template>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-6 col-lg-3">
                <div class="footer__column-wrapper">
                    <h3>Servizio clienti</h3>
                    <div class="footer__contacts">
                        <p>{{ environment.footer.customerServiceText }}</p>
                        <p class="contacts__mail">
                            <i class="icon icon-mail"></i>
                            <a [href]="'mailto:' + environment.organization.email">{{ environment.organization.email }}</a>
                        </p>
                        <p class="contacts__phone">
                            <i class="icon icon-phone"></i>
                            <a [href]="'tel:' + environment.organization.phoneNumber">{{ environment.organization.phoneNumber }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer__bottom">
        <div class="container">
            <div class="row">
                <!-- <div class="col-lg-4 col-footer">
                    <ul class="footer__menu footer__menu--inline">
                        <li class="menu__item">
                            <a href="#">Informativa sulla privacy e cookies</a>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-4 col-footer">
                    <ul class="menu footer__social">
                        <li class="menu__item">
                            <a href="#" target="_blank" rel="nofollow">
                                <i class="icon icon-twitter"></i>
                            </a>
                        </li>
                        <li class="menu__item">
                            <a href="#" target="_blank" rel="nofollow">
                                <i class="icon icon-linkedin"></i>
                            </a>
                        </li>
                        <li class="menu__item">
                            <a href="#" target="_blank" rel="nofollow">
                                <i class="icon icon-instagram"></i>
                            </a>
                        </li>
                        <li class="menu__item">
                            <a href="#" target="_blank" rel="nofollow">
                                <i class="icon icon-messanger"></i>
                            </a>
                        </li>
                    </ul>
                </div> -->
                <div class="col-lg-12 col-footer text-center">
                    <p class="footer__copyright w-100">
                        &copy; {{ date | date:'yyyy' }} {{ environment.organization.companyName }} - {{ 'footer.vatNumber' | translate}} {{ environment.organization.vatNumber }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>