import { AuthenticationService } from './authentication.service';
import { DiscountedPricePipe } from 'src/app/shared/pipes/discounted-price.pipe';
import { CartItem } from 'src/app/core/models/cart-item';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface CartTax {
  amount: number;
  vatAmount: number;
  tax: number;
  internal_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class CartDataService {
  cartTotal: BehaviorSubject<number> = new BehaviorSubject(0);
  taxes: { [key: string]: CartTax } = {};
  total: number;
  vat: number;
  totalWithTaxes: number;
  defaultDiscount: number;

  constructor(
    private discountedPricePipe: DiscountedPricePipe,
    private authService: AuthenticationService
  ) {
    this.defaultDiscount = this.authService.customer?.value?.default_discount;
  }

  calculateTotals(cartItems: CartItem[]) {
    this.taxes = {};
    this.total = this.vat = this.totalWithTaxes = 0;
    for (const cartItem of cartItems) {
      const tax: CartTax = this.taxes[cartItem.product.tax.internal_id] ?? {
        amount: 0,
        vatAmount: 0,
        tax: cartItem.product.tax.value,
        internal_id: cartItem.product.tax.internal_id,
      };

      const amount =
        tax.amount +
        this.discountedPricePipe.transform(cartItem, this.defaultDiscount) *
          cartItem.quantity;
      this.taxes[cartItem.product.tax.internal_id] = {
        amount,
        vatAmount: amount * cartItem.product.tax.value,
        tax: tax.tax,
        internal_id: tax.internal_id,
      };
    }
    for (const tax of Object.values(this.taxes)) {
      this.total += tax.amount;
      this.vat += tax.vatAmount;
      this.totalWithTaxes += tax.amount + tax.vatAmount;
    }

    this.cartTotal.next(this.totalWithTaxes);
  }

  getCalculatedTotals(cartItems: CartItem[], defaultDiscount: number) {
    let taxes: { [key: string]: CartTax } = {};
    let total = 0;
    let vat = 0;
    let totalWithTaxes = 0;

    for (const cartItem of cartItems) {
      const tax: CartTax = taxes[cartItem.product.tax.internal_id] ?? {
        amount: 0,
        vatAmount: 0,
        tax: cartItem.product.tax.value,
        internal_id: cartItem.product.tax.internal_id,
      };

      const amount =
        tax.amount +
        this.discountedPricePipe.transform(cartItem, defaultDiscount) *
          cartItem.quantity;
      taxes[cartItem.product.tax.internal_id] = {
        amount,
        vatAmount: amount * cartItem.product.tax.value,
        tax: tax.tax,
        internal_id: tax.internal_id,
      };
    }
    for (const tax of Object.values(taxes)) {
      total += tax.amount;
      vat += tax.vatAmount;
      totalWithTaxes += tax.amount + tax.vatAmount;
    }

    return {
      total,
      vat,
      totalWithTaxes,
    };
  }
}
