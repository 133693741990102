<header class="app-header" (window:resize)="onResize($event)">
  <!-- Top bar -->
  <div class="header__top">
    <div class="container">
      <div class="row" >
        <div class="col-12 col-lg-6">
          {{ environment.welcomeText }}
        </div>
        <div class="col-12 col-lg-6 text-lg-right">
          <fa-icon [icon]="icons.faPhoneAlt" class="fa-spin text-primary"></fa-icon> {{ environment.organization.phoneNumber }}
        </div>
      </div>
    </div>
  </div>
  <!-- Header middle -->
  <div class="header__middle">
    <div class="container">
      <div class="row" [ngClass]="{'justify-content-between': environment.disabledEcommerce || !customer || !customer.id}">
        <div class="header__logo col-6 col-lg-3">
          <a href="/">
            <img
              src="{{ environment.organization.logoUrl }} "
              alt="{{ environment.organization.companyName }}"
              class="middle__logo"
            />
          </a>
        </div>
        <div class="header__search col-12 col-lg-6">
          <app-search></app-search>
        </div>
        <div class="header__buttons col-6 col-lg-3 d-flex" *ngIf="!environment.disabledEcommerce && customer && customer.id">
          <div class="d-inline-block ml-auto">
            <button class="btn btn-rounded" routerLink="/cart">
              <i class="icon icon-cart icon--with-space"></i> {{ total | currency:'EUR' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header menu -->
  <div class="header__bottom" [ngClass]="{'opened': menuOpen}">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-9 header__nav-container">
          <button
            class="btn header__btn-products"
            (click)="toggleProductDropdown()"
          >
            Prodotti <i class="icon icon-menu"></i>
          </button>
          <div class="align-items-center d-flex h-100 justify-content-end position-static">
            <div class="align-items-center d-block d-flex d-lg-none h-100">
              <ng-container  [ngTemplateOutlet]="menuDropdown" [ngTemplateOutletContext]="{isMobile: true}">
  
              </ng-container>
            </div>
          <button class="d-lg-none header__menu-mobile-button" (click)="toggleMenu()" >
            <i class="icon icon-menu"></i>
            </button>
          <nav class="header__horizontal-nav  d-lg-block" [ngClass]="{'opened': menuOpen}">
            <ul>
              <li *ngFor="let item of environment.menu.header">
                <a
                  *ngIf="item.routerLink; else hrefLink"
                  [routerLink]="item.routerLink"
                  (click)="closeMenu()"
                  >{{ item.label }}</a
                >
                <ng-template #hrefLink>
                  <a href="{{ item.href }}">{{ item.label }}</a>
                </ng-template>
              </li>
            </ul>
            <ul *ngIf="!customer || !customer.id">
              <li *ngFor="let item of environment.menu.headerNotLoggedIn" class="d-block d-lg-none">
                <a *ngIf="item?.routerLink else hrefLink" [routerLink]="item.routerLink" [target]="item.target ? item.target : '_self'" 
                (click)="closeMenu()">{{item.label}}</a>
                <ng-template #hrefLink>
                  <a href="{{ item.href }}" [target]="item.target ? item.target : '_self'" >{{ item.label }}</a>
                </ng-template>
              </li>
            </ul>
          </nav>
          </div>
        </div>
        <div class="d-none d-lg-flex justify-content-end col-3" >
          <ng-container [ngTemplateOutlet]="menuDropdown" [ngTemplateOutletContext]="{isMobile: false}"></ng-container>
        </div>
      </div>

      <div class="header__products-dropdown" *ngIf="productsDropdown.isVisible">
        <div class="container">
          <ng-container *ngIf="!isLoading; else spinnerIcon">
            <ng-container *ngIf="mainCategories.length > 0; else noProducts">
              <ul class="header_main-categories-list" #productsMenu (mouseleave)="closeAllChildMenus()"> 
                <li class="products-dropdown__level-1 d-flex align-items-center"
                  *ngFor="let category of mainCategories; let i = index"
                  >
                  <span class="d-flex align-items-center w-100">
                    <fa-icon [icon]="icons.faAngleRight" (click)='openChildMenu($event,i, true)' [ngClass]="{'hiddenInMobile': !(getChildCategoriesById(category.id).length > 0), 'childOpened': openedIndex === i}"></fa-icon>
                    <div class="products-dropdown__item">
                    <a (mouseover)='openChildMenu($event, i)' (mouseleave)="clearTimeout()" (click)="hideProductDropdown()"  [routerLink]="['/category', category.slug]">
                      {{ category.title }}
                    </a>
                  </div>
                </span>
                    <ul class="products-dropdown__level-2"  *ngIf="getChildCategoriesById(category.id).length > 0">
                        <li class="products-dropdown__level-2-title w-100">
                          <h4>{{category.title}}</h4>
                        </li>
                        <li *ngFor="let childCategory of getChildCategoriesById(category.id)" class="d-flex align-items-center">
                          <fa-icon [icon]="icons.faAngleRight" class="mr-2"></fa-icon>
                          <a [routerLink]="['/category', childCategory.slug]" class="flex-grow-1" (click)="hideProductDropdown()">{{childCategory.title}}</a>
                        </li>
                    </ul>
                </li>
              </ul>
            </ng-container>
            <ng-template #noProducts>
              <div class="products-dropdown__level-1">No categories found</div>
            </ng-template>
  
          </ng-container>
          <ng-template #spinnerIcon>
            <fa-icon [icon]="icons.faSpinner" class="fa-spin"></fa-icon>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</header>
<ng-template #menuDropdown let-isMobile="isMobile">
  <div class="align-items-center h-100 header__nav-container header__nav-container--right" [ngClass]="{'header__nav-container--padding': customer && customer.id}">
    <div ngbDropdown *ngIf="customer && customer.id"  id="menuHeader" class="d-flex justify-content-center align-items-center w-100 h-100">
      <button class="dropdown-toggler text-right w-100" *ngIf="!isMobile" ngbDropdownToggle>
        <span class="company-name">{{customer.company_name}}</span>
        <span class="internal-id">{{customer.internal_id}}</span>
      </button>
      <fa-icon [icon]="icons.faUser" class="align-items-center d-flex dropdown-toggle h-100 justify-content-center" *ngIf="isMobile" ngbDropdownToggle></fa-icon>
      <div ngbDropdownMenu aria-labelledby="menuHeader" [ngClass]="{'right-unset': isMobile}" class="mt-0">
        <button ngbDropdownItem routerLink="/account">Modifica profilo</button>
        <button ngbDropdownItem routerLink="/account/orders">I miei ordini</button>
        <ng-container *ngIf="capabilities$ | async; let capabilities">
          <button ngbDropdownItem routerLink="/reports/carts" *ngIf="capabilities.includes('reports')">Report carrelli</button>
        </ng-container>
       
        <button ngbDropdownItem (click)="logout()">Esci</button>
      </div>
    </div>
    <nav class="header__horizontal-nav d-none d-lg-block" *ngIf="!customer || !customer.id">
      <ul class="collapse navbar-collapse">
        <li *ngFor="let item of environment.menu.headerNotLoggedIn">
          <a *ngIf="item?.routerLink else hrefLink" [routerLink]="item.routerLink" [target]="item.target ? item.target : '_self'">{{item.label}}</a>
          <ng-template #hrefLink>
            <a href="{{ item.href }}" [target]="item.target ? item.target : '_self'">{{ item.label }}</a>
          </ng-template>
        </li>
      </ul>
    </nav>
  </div>
</ng-template>