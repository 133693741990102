// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment, SocialMenuType } from '../core/models/environment';

export const environment: Environment = {
  production: false,
  serverUrl: "https://api.ordini-app.it",
  clientId: "givafer-ecommerce",
  clientSecret: "4yAwTuaCOEXIeAcUnbOT0S6SRve4y4p9",
  googleTagManagerId: "GTM-K4XQHDZ",
  organization: {
    companyName: "Givafer",
    externalId: "givafer",
    logoUrl: "https://api.ordini-app.it/api/ecommerce/givafer/static/logo.png",
    phoneNumber: "+39 095 574664",
    faxNumber: "+39 095 7130473",
    email: "info@givafer.it",
    address: "S.S. 192 - Km 83,400 - Fondo 18/b, 95121 Catania (CT)",
    vatNumber: "05063470875",
    mapEmbedSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3166.7068499369725!2d15.033797615724529!3d37.46764287981659!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1313e2790578eb69%3A0xd28c62c6bf61b046!2sGivafer%20srl!5e0!3m2!1sit!2sit!4v1615894773530!5m2!1sit!2sit",
  },
  seo: {
    home: {
      title:
        "Givafer | Ingrosso e distribuzione di articoli ferramenta, idraulica, giardinaggio e casalinghi",
    },
    product: {
      title: "{productName} | Givafer",
    },
    category: {
      title: "{title} | Givafer",
    },
    search: {
      title: "Risultati ricerca: {keyword} | Givafer",
    },
    brands: {
      title: "Marchi trattati | Givafer",
    },
    cart: {
      title: "Carrello | Givafer",
    },
    account: {
      title: "Account | Givafer",
    },
    contact: {
      title: "Contattaci | Givafer",
    },
    "page.chi-siamo": {
      title: "Chi siamo | Givafer",
    },
    "page.catalogo": {
      title: "Catalogo | Givafer",
    },
  },
  welcomeText: "Benvenuto! Il nostro servizio clienti è a tua disposizione!",
  disabledEcommerce: false,
  limitCartQuantity: false,
  menu: {
    header: [
      { label: "Home", routerLink: "/" },
      { label: "Azienda", routerLink: "/page/chi-siamo" },
      { label: "Catalogo", routerLink: "/page/catalogo" },
      { label: "Marchi", routerLink: "/brands" },
      { label: "Contatti", routerLink: "/contact" },
    ],
    headerNotLoggedIn: [
      { label: "Accedi", routerLink: "/login" },
      { label: "Registrati", href: "/register" },
      // {label: 'Ordini clienti', href: 'https://clientigivafer.ordini-web.it/', target: '_blank'}
    ],
    footer: [
      { label: "Home", routerLink: "/" },
      { label: "Azienda", href: "/page/chi-siamo" },
      { label: "Catalogo", routerLink: "/page/catalogo" },
      { label: "Marchi", href: "/brands" },
      { label: "Contatti", routerLink: "/contact" },
    ],
    social: [
      {
        type: SocialMenuType.Facebook,
        href: "https://facebook.com",
      },
    ],
  },
  contactForm: {
    introductionText:
      "Puoi contattarci per una richiesta di informazioni, richiedere una visita da parte di un'agente oppure per assistenza.",
  },
  footer: {
    introductionText:
      "La Givafer s.r.l. commercializza oltre 15.000 articoli destinati all’industria, alla ferramenta, all’edilizia, all’agricoltura ed all’enologia e conta, oggi, più di 2.000 clienti distribuiti in Sicilia e Calabria.",
    customerServiceText:
      "Per domande o richieste di aiuto, contatta il nostro servizio clienti.",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
