import { Customer } from './../models/customer';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  user: BehaviorSubject<User> = new BehaviorSubject(this.getUser());
  capabilities: BehaviorSubject<string[]> = new BehaviorSubject(
    this.getCapabilities()
  );
  customer: BehaviorSubject<Customer | null> = new BehaviorSubject(
    this.getCustomer()
  );
  userLoggedOut: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {}

  public setUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
    this.user.next(user);
    this.userLoggedOut.next(false);
  }

  public getUser(): User | null {
    try {
      const user = localStorage.getItem('user');
      return user ? (JSON.parse(user) as User) : null;
    } catch (error) {
      return null;
    }
  }

  public setCustomer(customer: Customer): void {
    localStorage.setItem('customer', JSON.stringify(customer));
    this.customer.next(customer);
  }

  public setCapabilities(capabilities: string[]): void {
    localStorage.setItem('capabilities', JSON.stringify(capabilities));
    this.capabilities.next(capabilities);
  }

  public getCapabilities(): string[] | null {
    const capabilities = localStorage.getItem('uscapabilitieser');
    return capabilities ? (JSON.parse(capabilities) as string[]) : null;
  }

  public getCustomer(): Customer | null {
    try {
      const customer = localStorage.getItem('customer');
      return customer ? (JSON.parse(customer) as Customer) : null;
    } catch (error) {
      return null;
    }
  }

  public logout(): void {
    localStorage.removeItem('user');
    localStorage.removeItem('customer');
    this.customer.next(null);
    this.user.next(null);
    this.userLoggedOut.next(true);
  }

  public isUserAuthenticated(): boolean {
    return !!(localStorage.getItem('user') && localStorage.getItem('customer'));
  }
}
