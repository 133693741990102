import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CategoryResponse } from '../models/category-response';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private httpClient: HttpClient) {}

  getCategories(): Observable<CategoryResponse> {
    return this.httpClient.get<CategoryResponse>(
      `${environment.serverUrl}/api/ecommerce/${environment.organization.externalId}/categories`
    );
  }
}
