import { DiscountService } from '../services/discount.service';
import { Pipe, PipeTransform } from '@angular/core';
import { Product } from 'src/app/core/models/product';
import { CartItem } from 'src/app/core/models/cart-item';

@Pipe({
  name: 'originalPrice'
})
export class OriginalPricePipe implements PipeTransform {

  constructor(private discountService: DiscountService) {}

  transform(value: Product|CartItem): number {
    let price = value.product.price;
    
    if(value.pricing){
        price = value.pricing.price;
    }

    return price;
  }

}
