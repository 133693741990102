<div
    class="search input-group"
    tabindex="1" 
    (keyup.arrowdown)="selectedIndex = (selectedIndex < searchResults.length) ? selectedIndex + 1 : selectedIndex"
    (keyup.arrowup)="selectedIndex = (selectedIndex > 0) ? selectedIndex - 1 : selectedIndex"
    (keyup.enter)="goToProductPage(selectedIndex)"
    (focusout)="closeAutoComplete($event)">
    <span class="input-group-prepend">
        <div class="input-group-text search__icon">
            <img src="assets/icons/icon-search.png" *ngIf="!isLoading else spinnerIcon">
            <ng-template #spinnerIcon>
                <fa-icon [icon]="faSpinner" class="fa-spin"></fa-icon>
            </ng-template>
        </div>
    </span>
    <input class="form-control search__input" (focus)="openAutoComplete()"  type="search" name="searchField" [formControl]="searchField" placeholder="{{ 'header.search.placeholder' | translate}}" autocomplete="off">
    <span class="input-group-append">
        <button class="btn btn-primary border-left-0 border search__button" type="button"  [routerLink]="['category']" [queryParams]="{ search: searchField.value }">
            {{ 'header.search.button' | translate}}
        </button>
    </span>

    <div class="search__autocomplete" *ngIf="searchResults.length && showResults">
        <div *ngFor="let result of searchResults; let i = index"
            class="search__suggestion"
            [routerLink]="['product', result?.product?.slug]"
            (click)="closeAutoComplete('close')"
            (mouseenter)="selectedIndex = i"
            [ngClass]="{'search__suggestion--active': selectedIndex === i}">
            <div class="row">
                <div class="col-2 d-flex">
                    <img [src]="imageUrlService.getImageUrl(result.product.id)" class="suggestion__image img-fluid">
                </div>
                <div class="col-10">
                    <strong>{{result?.product?.description}}</strong>
                        <p>
                            <span>Cod. {{result.product?.internal_id}}</span><br/>
                        </p>
                        <!-- <del>{{result.product?.price | currency: 'EUR'}}/{{result?.product?.unit_of_measurement}}</del> <strong> {{result?.product?.price | currency:'EUR'}}/{{result?.product?.unit_of_measurement}}</strong> (-10%) -->
                        <p class="suggestion__price" *ngIf="customerId">
                            <span *ngIf="result.product.deal || result.product.pricing || defaultDiscount" class="price price--old">
                                {{result | originalPrice | currency : 'EUR'}} 
                            </span>
                            <span class="price price--active">
                                {{result | discountedPrice:defaultDiscount | currency : 'EUR'}}
                            </span>
                            <span class="price__unit_of_measurement">
                               / {{ result.product.unit_of_measurement }}
                            </span>
                        </p>
                </div>
            </div>
        </div>
        <a 
            [routerLink]="['category']"
            [queryParams]="{ search: searchField.value }"
            (click)="showResults = false"
            [ngClass]="{'search__suggestion--active': selectedIndex === searchResults.length}"
            (mouseenter)="selectedIndex = searchResults.length"
            class="search__suggestion">
            <div class="row">
                <div class="col-12 text-center">
                    {{ 'header.search.viewAllResults' | translate:{totalRecords: totalResults} }}
                </div>
            </div>
        </a>
        
    </div>
</div>
