<div class="overlay" *ngIf="showSpinner">
    <div class="overlay__wrapper">
        <div class="overlay__spinner">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
<app-header></app-header>
<div class="toasts">
    <ngb-toast
        class="toast toast--logout toast-info ml-auto" role="alert" 
        *ngFor="let toast of toastService.toasts"
        [header]="toast.header" 
        [autohide]="true" [delay]="toast.delay || 8000"
        (hide)="toastService.remove(toast)"
    ><div [innerHtml]="toast.body | safeHtml" *ngIf="toast.body"></div></ngb-toast>
</div>

<router-outlet></router-outlet>

<app-page-overlay></app-page-overlay>
<app-footer></app-footer>