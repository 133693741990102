import { CartDataService } from './../../core/services/cart-data.service';
import { AuthenticationService } from './../../core/services/authentication.service';
import { SortDirection } from './../../shared/enums/sort-directon';
import { HeaderService } from './services/header.service';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import {
  faShoppingCart,
  faSpinner,
  faAngleRight,
  faTimes,
  faUser,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import { PageOverlayService } from '../page-overlay/page-overlay.service';
import { Product } from 'src/app/core/models/product';
import { Category } from 'src/app/core/models/category';
import { Customer } from 'src/app/core/models/customer';
import { LoginApiService } from 'src/app/core/http/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('productsMenu') menu: any;
  icons = {
    faShoppingCart,
    faSpinner,
    faAngleRight,
    faTimes,
    faUser,
    faPhoneAlt,
  };
  isLoading: boolean;
  environment = environment;
  productsDropdown = {
    isVisible: false,
    products: [],
  };
  products: Product[];
  mainCategories: Category[];
  categories: Category[];
  timer: any;
  menuOpen = false;
  openedIndex: number;
  customer: Customer;
  total: any;
  capabilities$ = this.authenticationService.capabilities;

  constructor(
    private translate: TranslateService,
    private pageOverlayService: PageOverlayService,
    private render: Renderer2,
    private authenticationService: AuthenticationService,
    private headerService: HeaderService,
    private cartDataService: CartDataService,
    private loginService: LoginApiService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.authenticationService.customer.subscribe({
      next: (customer) => {
        this.customer = customer;
        if (this.customer) {
          this.getCartData();
        }
      },
    });
    this.headerService.getCategories().subscribe((res) => {
      this.categories = res.records;
      this.mainCategories = res.records.filter(
        (category) => !category.parent_id
      );
      this.isLoading = false;
    });
    this.pageOverlayService.click.subscribe(() => {
      this.hideProductDropdown();
      this.menuOpen = false;
    });
  }

  getCartData(): void {
    this.headerService.getCart(this.customer.id.toString()).subscribe({
      next: (res) => {
        if (res.status) {
          this.cartDataService.calculateTotals(res.cart);
        }
      },
    });
    this.cartDataService.cartTotal.subscribe({
      next: (response) => {
        this.total = this.cartDataService.total;
      },
    });
  }

  getChildCategoriesById(id: number) {
    return this.categories.filter((category) => category.parent_id === id);
  }

  toggleProductDropdown(): void {
    this.menuOpen = false;
    this.openedIndex = null;
    this.productsDropdown.isVisible = !this.productsDropdown.isVisible;
    this.pageOverlayService.visibility.next(this.productsDropdown.isVisible);
  }

  logout(): void {
    this.authenticationService.logout();
    this.loginService.logout();
  }

  hideProductDropdown(): void {
    this.productsDropdown.isVisible = false;
    this.openedIndex = null;
    this.pageOverlayService.hide();
  }

  clearTimeout() {
    clearTimeout(this.timer);
  }

  openChildMenu(event, index: number, isMobile?: boolean) {
    if (isMobile) {
      const childElement = event.currentTarget.parentElement.nextSibling;
      if (childElement?.className) {
        if (childElement?.className.includes('opened')) {
          this.closeAllChildMenus();
          this.openedIndex = null;
        } else {
          this.closeAllChildMenus();
          this.render.addClass(childElement, 'opened');
          this.openedIndex = index;
        }
      }
    } else {
      const childElement =
        event.srcElement.parentElement.parentElement.nextSibling;
      this.timer = setTimeout(() => {
        if (childElement) {
          this.closeAllChildMenus();
          this.render.addClass(childElement, 'opened');
        }
      }, 250);
    }
  }

  closeAllChildMenus(): void {
    this.menu?.nativeElement
      ?.querySelectorAll('.products-dropdown__level-2')
      .forEach((el) => {
        if (el) {
          this.render.removeClass(el, 'opened');
        }
      });
  }

  toggleMenu(): void {
    this.productsDropdown.isVisible = false;
    this.menuOpen = !this.menuOpen;

    if (this.menuOpen) {
      this.pageOverlayService.show(false);
    } else {
      this.pageOverlayService.hide();
    }
  }

  onResize(event): void {
    if (event.target.innerWidth >= 992) {
      this.closeMenu();
    }
  }

  closeMenu(): void {
    this.menuOpen = false;
    this.pageOverlayService.hide();
  }
}
