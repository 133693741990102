export interface Environment {
  production: boolean;
  serverUrl: string;
  clientId: string;
  clientSecret: string;
  googleTagManagerId: string;
  organization: {
    companyName: string;
    externalId: string;
    logoUrl: string;
    phoneNumber: string;
    faxNumber: string;
    email: string;
    address: string;
    vatNumber: string;
    mapEmbedSrc: string;
  };
  seo?: Seo;
  welcomeText?: string;
  disabledEcommerce: boolean;
  limitCartQuantity: boolean;
  menu: {
    header?: MenuItem[];
    headerNotLoggedIn?: MenuItem[];
    footer?: MenuItem[];
    social?: SocialMenuItem[];
  };
  contactForm?: {
    introductionText?: string;
  };
  footer?: {
    introductionText?: string;
    customerServiceText?: string;
  };
}

export interface MenuItem{
    label?: string;
    routerLink?: string;
    href?: string;
    target?: string;
}

export enum SocialMenuType{
    Facebook,
    Instagram,
    Linkedin,
    Twitter
}
export interface SocialMenuItem extends MenuItem{
    type: SocialMenuType
}

export interface Seo {
    [index: string]: {title: string; description?: string;};
}