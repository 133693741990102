import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductQuery } from 'src/app/shared/models/product-query';
import { environment } from 'src/environments/environment';
import { Product } from '../models/product';
import { ProductResponse } from '../models/product-response';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private httpClient: HttpClient) {}

  getProducts(params: ProductQuery): Observable<ProductResponse> {
    const queryParams = new HttpParams()
      .append('search', params.search ?? '')
      .append('length', params.length?.toString())
      .append('current_page', params.current_page?.toString())
      .append('sort_by', params.sortBy ?? 'id')
      .append('sort_direction', params.sort_direction ?? 'DESC')
      .append('category_id', params.category_id ?? '')
      .append('category_slug', params.category_slug ?? '')
      .append('deals', params.deals?.toString() ?? '')
      .append('customer_id', params.customer_id?.toString() ?? '')
      .append('featured', params.featured?.toString() ?? '')
      .append('brand_id', params.brand_id ? params.brand_id.toString() : '')
      .append('brand_slug', params.brand_slug ?? '');
    return this.httpClient.get<ProductResponse>(
      `${environment.serverUrl}/api/ecommerce/${environment.organization.externalId}/products`,
      { params: queryParams }
    );
  }

  getProductById(id: number, customerId?: number): Observable<Product> {
    const queryParams = new HttpParams().append(
      'customer_id',
      customerId ? customerId.toString() : ''
    );
    return this.httpClient.get<Product>(
      `${environment.serverUrl}/api/ecommerce/${environment.organization.externalId}/product/${id}`,
      {
        params: customerId ? queryParams : null,
      }
    );
  }
}
