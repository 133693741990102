import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Toast } from 'src/app/core/models/toast';


@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toastSubject: BehaviorSubject<Toast> = new BehaviorSubject({show: false, message: ''});

  toasts: any[] = [];

  constructor() { }

  toggle(data) {
    console.log(data);
    this.toastSubject.next(data);
  }

  show(header: string, body?: string, delay?: number) {
    this.toasts.push({ header, body, delay });
    console.log(this.toasts);
  }

  remove(toast) {
    this.toasts = this.toasts.filter(t => t != toast);
  }
}
