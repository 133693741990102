import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  ECommerceTokenResponse,
  TokenResponse,
} from '../models/token-response';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class LoginApiService {
  loading$ = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService
  ) {}

  async getApiToken(): Promise<string> {
    if (this.loading$.value) {
      await new Promise((resolve) => {
        const loadingSubscription = this.loading$.subscribe((value) => {
          if (value === false) {
            loadingSubscription.unsubscribe();
            resolve(value);
          }
        });
      });
    }

    if (this.refreshToken && this.isTokenExpired) {
      // Refresh the token
      await this.refreshAccessToken();
    } else if (!this.accessToken || this.isTokenExpired) {
      // Request a new public token
      await this.retrievePublicToken();
    }

    return this.accessToken;
  }

  logout() {
    this.authenticationService.logout();
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expire_time');
  }

  login(
    username: string,
    password: string
  ): Observable<ECommerceTokenResponse> {
    return this.httpClient
      .post<ECommerceTokenResponse>(
        `${environment.serverUrl}/api/ecommerce/${environment.organization.externalId}/token`,
        {
          grant_type: 'password',
          username,
          password,
          client_id: environment.clientId,
          client_secret: environment.clientSecret,
        }
      )
      .pipe(
        tap((response: ECommerceTokenResponse) => {
          this.storeTokens(response);
        })
      );
  }

  async retrievePublicToken() {
    this.loading$.next(true);
    this.logout();
    return await this.httpClient
      .post<TokenResponse>(`${environment.serverUrl}/api/token`, {
        grant_type: 'client_credentials',
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
      })
      .pipe(
        tap((res) => this.storeTokens(res)),
        filter((res) => !!res.access_token)
      )
      .toPromise();
  }

  private async refreshAccessToken() {
    this.loading$.next(true);
    return await this.httpClient
      .post<ECommerceTokenResponse>(`${environment.serverUrl}/api/token`, {
        grant_type: 'refresh_token',
        client_id: environment.clientId,
        client_secret: environment.clientSecret,
        refresh_token: this.refreshToken,
      })

      .pipe(
        tap((res) => this.storeTokens(res)),
        filter((response) => !!response.access_token),
        catchError(() => {
          this.loading$.next(false);
          this.logout();

          return null;
        })
      )
      .toPromise();
  }

  get accessToken() {
    return localStorage.getItem('access_token');
  }

  get refreshToken() {
    return localStorage.getItem('refresh_token');
  }

  get expireTime(): number | null {
    return +localStorage.getItem('expire_time');
  }

  private storeTokens(response: ECommerceTokenResponse) {
    this.loading$.next(false);
    if (response.refresh_token) {
      localStorage.setItem('refresh_token', response.refresh_token);
    }

    localStorage.setItem('access_token', response.access_token);
    localStorage.setItem(
      'expire_time',
      (Date.now() + response.expires_in * 1000).toString()
    );
  }

  get isTokenExpired(): boolean {
    if (!this.expireTime) return true;
    return Date.now() > this.expireTime;
  }
}
