import { AfterContentInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { delay } from 'rxjs/operators';
import { UserService } from './core/http/user.service';
import { Customer } from './core/models/customer';
import { Toast } from './core/models/toast';
import { User } from './core/models/user';
import { AuthenticationService } from './core/services/authentication.service';
import { SpinnerService } from './core/services/spinner.service';
import { ToastService } from './shared/services/toast.service';
import { LoginApiService } from './core/http/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentInit {
  showSpinner: boolean;
  userLoggedout: boolean;

  toast: Toast = {
    show: false,
    message: '',
  };

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private spinnerService: SpinnerService,
    public toastService: ToastService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private loginService: LoginApiService
  ) {
    translate.setDefaultLang('it');
    this.showSpinner = true;
  }

  ngOnInit(): void {
    this.setLoggedInUser();
    this.authenticationService.userLoggedOut.subscribe(
      (res) => (this.userLoggedout = res)
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.gtmService.pushTag({
          event: 'page',
          pageName: event.url,
        });
      }
    });
  }

  ngAfterContentInit(): void {
    this.spinnerService.isDataLoading.pipe(delay(0)).subscribe({
      next: (res) => {
        this.showSpinner = res;
      },
    });
  }

  private getUser(): void {
    this.userService.getUser().subscribe({
      next: (res) => {
        this.authenticationService.setUser(res.user);
        if (res.capabilities) {
          this.authenticationService.setCapabilities(res.capabilities);
        }
      },
      error: (_) => {
        this.spinnerService.isDataLoading.next(false);
        if (localStorage.getItem('user')) {
          this.userLoggedout = false;
          this.authenticationService.logout();
          this.loginService.logout();
        }
      },
    });
  }

  private setLoggedInUser(): void {
    if (this.authenticationService.getUser()) {
      this.getUser();
    }

    const customer = this.authenticationService.getCustomer();

    if (customer) {
      this.authenticationService.setCustomer(customer);
    }
  }
}
