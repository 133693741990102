import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor() { }

  calculateDiscountedPrice(
    price: number,
    discounts: number[]
  ): number {
    let discountedPrice = price;
    discounts.forEach((discount) => {
      const tempDiscount = price * (discount / 100);
      discountedPrice -= tempDiscount;
    });

    return discountedPrice;
  }
}
