import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageOverlayService {

  visibility: BehaviorSubject<boolean> = new BehaviorSubject(false);
  click: EventEmitter<null> = new EventEmitter(null);

  constructor() { }

  show(hideOverflow = true){
    this.visibility.next(true);
    if(hideOverflow){
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    }
  }

  hide(){
    this.visibility.next(false);
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }
}
