import { DiscountedPricePipe } from 'src/app/shared/pipes/discounted-price.pipe';
import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterModule } from './components/footer/footer.module';
import { HeaderModule } from './components/header/header.module';
import { PageOverlayModule } from './components/page-overlay/page-overlay.module';
import { SharedModule } from './shared/shared.module';
import localeIt from '@angular/common/locales/it';
import { ThemeModule } from './theme/theme.module';
import localeItExtra from '@angular/common/locales/extra/it';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { ToastService } from './shared/services/toast.service';
import { environment } from 'src/environments/environment';
import { LoginApiService } from './core/http/login.service';

registerLocaleData(localeIt, 'it-IT', localeItExtra);

export function initializeAppCustomLogic(
  service: LoginApiService
): () => Promise<void> {
  return () =>
    new Promise<void>(async (resolve) => {
      await service.getApiToken();
      resolve();
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HeaderModule,
    FooterModule,
    PageOverlayModule,
    ThemeModule,
  ],
  providers: [
    TranslateService,
    ToastService,
    DiscountedPricePipe,
    {
      provide: LOCALE_ID,
      useValue: 'it-IT',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppCustomLogic,
      deps: [LoginApiService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
