import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserRequest } from '../models/user-request';
import { UserResponse } from './../models/user-response';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  getUser(): Observable<UserResponse> {
    return this.httpClient.get<UserResponse>(
      `${environment.serverUrl}/api/user/get`
    );
  }

  registerUser(
    newUser: UserRequest
  ): Observable<{ status: boolean; message: string }> {
    return this.httpClient.post<{ status: boolean; message: string }>(
      `${environment.serverUrl}/api/ecommerce/${environment.organization.externalId}/account/register`,
      newUser
    );
  }

  passwordRecovery(
    username: string
  ): Observable<{ status: boolean; message: string }> {
    return this.httpClient.post<{ status: boolean; message: string }>(
      `${environment.serverUrl}/api/ecommerce/${environment.organization.externalId}/account/recoveryPassword`,
      {
        username,
      }
    );
  }

  updateUser(user: UserRequest) {
    return this.httpClient.post<{ status: boolean; message: string }>(
      `${environment.serverUrl}/api/user/post`,
      user
    );
  }
}
